export function baseShow(addToast, t, text, options, overrideTimeout = null, noT = false) {
  const opts = options;
  if (overrideTimeout) opts.autoDismissTimeout = overrideTimeout;
  addToast(noT ? text : t(text), opts);
}

export function baseSuccess(addToast, t, text, overrideTimeout = 1500, noT = false) {
  const options = { appearance: 'success', autoDismiss: true };
  baseShow(addToast, t, text, options, overrideTimeout, noT);
}

export function baseInfo(addToast, t, text, overrideTimeout = 5000, noT = false, id = null) {
  const options = { appearance: 'info', autoDismiss: true, id };
  baseShow(addToast, t, text, options, overrideTimeout, noT);
}

export function baseWarning(addToast, t, text, overrideTimeout = 10000, noT = false) {
  const options = { appearance: 'warning', autoDismiss: true };
  baseShow(addToast, t, text, options, overrideTimeout, noT);
}

export function baseError(addToast, t, text, overrideTimeout = 15000, noT = false) {
  const options = { appearance: 'error', autoDismiss: true };
  baseShow(addToast, t, text, options, overrideTimeout, noT);
}

export default class Toast {
  /**
   * Show Toast with options
   * @param {Object} props
   * @param {String} text
   * @param {Object} options
   * @param {any} overrideTimeout
   * @param {Boolean} noT
   */
  static show(props, text, options, overrideTimeout = null, noT = false) {
    if (!props || !props.toastManager || !props.t) return;
    baseShow(props.toastManager.add, props.t, text, options, overrideTimeout, noT);
  }

  /**
   * Show Toast success
   * @param {Object} props
   * @param {String} text
   * @param {any} overrideTimeout
   * @param {Boolean} noT
   */
  static success(props, text, overrideTimeout, noT) {
    baseSuccess(props.toastManager.add, props.t, text, overrideTimeout, noT);
  }

  /**
   * Show Toast info
   * @param {Object} props
   * @param {String} text
   * @param {any} overrideTimeout
   * @param {Boolean} noT
   */
  static info(props, text, overrideTimeout, noT, id = null) {
    baseInfo(props.toastManager.add, props.t, text, overrideTimeout, noT, id);
  }

  /**
   * Show Toast warning
   * @param {Object} props
   * @param {String} text
   * @param {any} overrideTimeout
   * @param {Boolean} noT
   */
  static warning(props, text, overrideTimeout, noT) {
    baseWarning(props.toastManager.add, props.t, text, overrideTimeout, noT);
  }

  /**
   * Show Toast error
   * @param {Object} props
   * @param {String} text
   * @param {any} overrideTimeout
   * @param {Boolean} noT
   */
  static error(props, text, overrideTimeout, noT) {
    baseError(props.toastManager.add, props.t, text, overrideTimeout, noT);
  }
}
